import { atom } from 'jotai';
import { atomWithReset, atomWithStorage } from 'jotai/utils';
import { orderBy, uniq } from 'lodash-es';
import { dataHome } from '@/components/exam/ExamHomeView/dataMock';
import { GetScoreRankQuery, TestLevels } from '@/graphql/generated/query';
import { CheckPointPlacementTest } from '@/types';
import { AvatarFrameType } from '@/__generated__/graphql';

export const testLevelAtomResponse = atom<TestLevels | undefined>(undefined);
export const scoreRankAtom = atom<GetScoreRankQuery | undefined>(undefined);
export const totalNotification = atom<number>(0);

export const testLevelsByTag = atom('');
export const filterCambridgeAtom = atom('all');

export const testLevelsPayload = atom((get) => {
  const tag = get(testLevelsByTag);

  const datas = dataHome?.map((item) => {
    const dataAdd = get(testLevelAtomResponse)?.testLevels?.find((_) => _.slug === item.slug);
    const isActive = !tag || tag === 'all' ? true : item.tag.includes(tag);

    return {
      ...item,
      ...dataAdd,
      active: isActive,
      tag: uniq([...item.tag, ...(dataAdd?.levels as [string])]),
    };
  });

  if (!tag) {
    return datas;
  }

  const newData = datas.map((item) => {
    if (tag) {
      return { ...item, active: item.tag.includes(tag) };
    }
    return { ...item, active: true };
  });

  return orderBy(newData, ['isDuplicated', (v) => v.tag.includes(tag)], ['asc', 'desc']);
});

export const checkPointPlacementTestAtom = atom<CheckPointPlacementTest | undefined>(undefined);
export const lastPlacementTestIdAtom = atomWithReset<string | undefined>(undefined);

export const timeUserStartVisitAtom = atomWithStorage<string | undefined>(
  'timeReChooseProfile',
  undefined,
);

export const profileViewRecapMonthlyAtom = atomWithStorage<{ profileId: string; date: string }[]>(
  'profileViewRecapMonthly',
  [],
);

export const profileViewRecapYearlyAtom = atomWithStorage<{ profileId: string; year: string }[]>(
  'profileViewRecapYearly',
  [],
);

export const handlerOnOffSoundAtom = atomWithStorage('soundSetting', false);

export const profileViewWhatNewsAtom = atomWithStorage<{ userId: string; appVersion: string }[]>(
  'profileViewWhatNews',
  [],
);

export const openModalEditAvatarAtom = atomWithReset(false);
export const frameAvatarTabAtom = atomWithReset(AvatarFrameType.Frame);
