import { useResetAtom } from 'jotai/utils';
import useTranslate from 'next-translate/useTranslation';
import { useApolloClient, useMutation, useQuery, useSubscription } from '@apollo/client';
import time from '@flyer/utils/time';
import { useCookieState } from 'ahooks';
import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from '@flyer/components/toast';
import { lastPlacementTestIdAtom, timeUserStartVisitAtom, totalNotification } from '@/store/home';
import {
  cookieProfileId,
  mgmReferralCode,
  utmCampaignId,
  utmContentId,
  utmMediumId,
  utmSourceId,
} from '@/lib/utils';
import { ENVIRONMENT } from '@/lib/config';
import { AuthProviderViewerSessionQueryQuery } from '@/__generated__/graphql';
import { graphql } from '@/__generated__';
import ModalButton from '@/components/common/Modal/components/ModalButton';
import ROUTES, { EXCLUDE_REQUIRED_SIGN_IN } from '@/lib/routes';
import * as Sentry from '@sentry/nextjs';
import mixpanel from 'mixpanel-browser';
import context from './authContext';

const AuthProviderCreateAnonymousLearnerProfileMutation = graphql(/* GraphQL */ `
  mutation AuthProviderCreateAnonymousLearnerProfileMutation(
    $input: ViewerCreateLearnerProfileInput!
  ) {
    viewerCreateLearnerProfile(input: $input) {
      learnerProfile {
        id: learnerProfileId
      }
      userErrors {
        ...UserError
      }
    }
  }
`);

const AuthProviderUpdateAnonymousLearnerProfileMutation = graphql(/* GraphQL */ `
  mutation AuthProviderUpdateAnonymousLearnerProfileMutation(
    $input: ViewerUpdateLearnerProfileInput!
  ) {
    viewerUpdateLearnerProfile(input: $input) {
      learnerProfile {
        id: learnerProfileId
      }
      userErrors {
        ...UserError
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment LearnerProfile on LearnerProfile {
    # id
    id: learnerProfileId
    learnerProfileId
    firstName
    lastName
    displayName
    dateOfBirth
    gender
    avatarUrl
    frameImage {
      id
      url
    }
    isAnonymous
    canClaimDailyReward
    canRequestTeacher
    lang
    classStudent {
      id
      class {
        id
        classId
        code
        name
      }
    }
    domains {
      id
      customDomainId
      domainName
      showDiamond
    }
    streakData {
      currentStreak {
        count
        endDate
        startDate
      }
    }
    coin
    star
    star
    placementLevel
    school {
      id: schoolId
      name
      province {
        id: provinceId
        name
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment Viewer on User {
    # id
    id: userId
    email
    phoneNumber
    emailVerified
    email
    username
    hasPassword
    subscriptionExpiresAt
    subscriptions(first: 20) {
      nodes {
        package {
          name
        }
      }
    }
    isSubscribing
    canCreateLearnerProfile
    diamond
    teacherVerified
    countryCode
    currentLearnerProfile {
      ...LearnerProfile
    }
    learnerProfiles(first: 20) {
      nodes {
        id: learnerProfileId
        learnerProfileId
        firstName
        lastName
        displayName
        dateOfBirth
        avatarUrl
        classStudent {
          class {
            id
            classId
            code
            name
          }
        }
        frameImage {
          id
          url
        }
        isAnonymous
      }
    }
    adminRole
  }
`);

const ViewerSessionQuery = graphql(/* GraphQL */ `
  query AuthProviderViewerSessionQuery {
    viewerSession {
      viewer {
        ...Viewer
      }
      loginSession {
        id: userSessionId
        active
        createdAt
        userAgent {
          summary
        }
        deactivatedBySession {
          id: userSessionId
          active
          createdAt
          userAgent {
            summary
          }
        }
      }
    }
  }
`);

const AuthProviderViewerLogOutMutation = graphql(/* GraphQL */ `
  mutation AuthProviderViewerLogOutMutation {
    viewerLogOut {
      userErrors {
        code
      }
    }
  }
`);

const AuthProviderViewerSwitchLearnerProfileMutation = graphql(/* GraphQL */ `
  mutation AuthProviderViewerSwitchLearnerProfileMutation($id: ID!) {
    viewerSwitchLearnerProfile(input: { learnerProfileId: $id }) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
      }
    }
  }
`);

const AuthProviderViewerSessionEvent = graphql(/* GraphQL */ `
  subscription AuthProviderViewerSessionEvent {
    viewerSessionEvent {
      event
    }
  }
`);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const client = useApolloClient();
  const resetPlacementAtom = useResetAtom(lastPlacementTestIdAtom);
  const [, setUtmSource] = useCookieState(utmSourceId);
  const [, setUtmMedium] = useCookieState(utmMediumId);
  const [, setUtmCampaign] = useCookieState(utmCampaignId);
  const [, setUtmContentId] = useCookieState(utmContentId);
  const [, setMgmReferralCode] = useCookieState(mgmReferralCode);
  const [isLogin, setIsLogin] = useState(false);
  const setCount = useSetAtom(totalNotification);

  const { data, loading, refetch } = useQuery(ViewerSessionQuery, {
    context: { v2: true },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ viewerSession }) => {
      if (viewerSession?.viewer) {
        setIsLogin(true);
        const scope = Sentry.getCurrentScope();
        scope.setUser(viewerSession?.viewer);
        mixpanel.identify(viewerSession.viewer.id);
        mixpanel.people.set({
          $name: viewerSession.viewer?.currentLearnerProfile?.displayName,
          $phone: viewerSession.viewer?.phoneNumber,
          $email: viewerSession.viewer?.email,
          $userName: viewerSession.viewer?.username,
          $subscriptionExpiresAt: viewerSession.viewer?.subscriptionExpiresAt,
        });
      } else {
        setIsLogin(false);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const user = data?.viewerSession?.viewer;

  const currentLearnerProfileFragment = user?.currentLearnerProfile;
  const currentLearnerProfile = useMemo(
    () =>
      currentLearnerProfileFragment
        ? { ...currentLearnerProfileFragment, id: currentLearnerProfileFragment.id.toString() }
        : undefined,
    [currentLearnerProfileFragment],
  );

  const learnerProfilesFragment = user?.learnerProfiles.nodes;
  const learnerProfiles = useMemo(
    () =>
      learnerProfilesFragment
        ? learnerProfilesFragment.map((lp) => ({ ...lp, id: lp.id.toString() }))
        : [],
    [learnerProfilesFragment],
  );

  useEffect(() => {
    if (user && typeof router.query.auth_redirect_url === 'string') {
      if (router.query.auth_redirect_url.startsWith('http')) {
        window.location.href = router.query.auth_redirect_url;
      } else {
        void router.replace(router.query.auth_redirect_url);
      }
    }
  }, [router, user]);

  const writeQueryViewer = useCallback(
    (viewerQuery: AuthProviderViewerSessionQueryQuery['viewerSession']) => {
      const d = { ...client.readQuery({ query: ViewerSessionQuery }) };
      if (d.viewerSession) {
        d.viewerSession = { ...d.viewerSession, ...viewerQuery };
      }
      client.writeQuery({
        query: ViewerSessionQuery,
        data: d,
      });
      void client.cache.reset();
    },
    [client],
  );

  const [commitLogOut] = useMutation(AuthProviderViewerLogOutMutation, {
    context: { v2: true },
    onError(error) {
      console.error(error);
    },
    onCompleted() {
      setIsLogin(false);
      writeQueryViewer({ viewer: null, loginSession: null });
    },
  });

  // TODO: rewrite this
  const [, setTimeUserStartVisit] = useAtom(timeUserStartVisitAtom);
  const [commitSwitchLearnerProfile, { loading: switchProfileLoading }] = useMutation(
    AuthProviderViewerSwitchLearnerProfileMutation,
    {
      context: { v2: true },
      onError(error) {
        console.error(error);
      },
      onCompleted({ viewerSwitchLearnerProfile }) {
        writeQueryViewer(viewerSwitchLearnerProfile);
        setCount(0);
        setTimeUserStartVisit(time().add(24, 'hours').format());
      },
    },
  );

  const switchLearnerProfile = useCallback(
    async (id: string) => {
      if (!user || switchProfileLoading) {
        return;
      }
      resetPlacementAtom();
      // add time when switch profile
      await commitSwitchLearnerProfile({ variables: { id } });
    },
    [commitSwitchLearnerProfile, resetPlacementAtom, switchProfileLoading, user],
  );

  // TODO: remove this after migrate old exam
  const [profileId, setProfileId] = useCookieState(cookieProfileId);

  const logOut = useCallback(
    async (redirectToHome = true) => {
      await commitLogOut();
      setProfileId(undefined);
      setIsLogin(false);
      resetPlacementAtom();
      if (redirectToHome) {
        await router.replace('/');
      }
    },
    [commitLogOut, resetPlacementAtom, router, setProfileId],
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (currentLearnerProfile?.id) {
      setProfileId(currentLearnerProfile?.id, {
        sameSite: 'none',
        secure: true,
        expires: 60 * 60 * 24 * 365,
        domain: window.location.hostname.replace(/^(.*\.)?([^.]+\.[^.]+)$/, '$2'),
      });
    } else {
      setProfileId(undefined, {
        sameSite: 'none',
        secure: true,
        domain: window.location.hostname.replace(/^(.*\.)?([^.]+\.[^.]+)$/, '$2'),
      });
    }
  }, [currentLearnerProfile?.id, loading, setProfileId]);

  const [anonymousLearnerProfileId, setAnonymousLearnerProfileId] = useCookieState(
    ENVIRONMENT.PUBLIC_ANONYMOUS_LEARNER_PROFILE_ID,
  );

  const [createProfileAnonymousUser] = useMutation(
    AuthProviderCreateAnonymousLearnerProfileMutation,
    { context: { v2: true } },
  );
  const [updateAnonymousUser] = useMutation(AuthProviderUpdateAnonymousLearnerProfileMutation, {
    context: { v2: true },
  });

  const createProfileAnonymous = useCallback(
    async ({ name }: { name?: string }) => {
      const dataUser = await createProfileAnonymousUser({
        variables: {
          input: { firstName: name || 'Flyer Member' },
        },
      });
      const learnerProfileId = dataUser.data?.viewerCreateLearnerProfile.learnerProfile?.id;

      if (learnerProfileId) {
        setAnonymousLearnerProfileId(learnerProfileId, {
          sameSite: 'none',
          secure: true,
          expires: 60 * 60 * 24 * 365,
          domain: window.location.hostname.replace(/^(.*\.)?([^.]+\.[^.]+)$/, '$2'),
        });
        return learnerProfileId;
      }
    },
    [createProfileAnonymousUser, setAnonymousLearnerProfileId],
  );

  const getCurrentLearnerProfileId = useCallback(
    async (name?: string) => {
      if (profileId) {
        return profileId;
      }

      // handler update or create
      if (anonymousLearnerProfileId && name) {
        try {
          const dataUserUpdate = await updateAnonymousUser({
            variables: { input: { id: anonymousLearnerProfileId, firstName: name } },
          });
          const idUser =
            dataUserUpdate.data?.viewerUpdateLearnerProfile.learnerProfile?.id.toString();
          if (idUser) {
            setAnonymousLearnerProfileId(idUser, {
              sameSite: 'none',
              secure: true,
              expires: 60 * 60 * 24 * 365,
              domain: window.location.hostname.replace(/^(.*\.)?([^.]+\.[^.]+)$/, '$2'),
            });
            return idUser;
          }
        } catch {
          return createProfileAnonymous({ name });
        }
      } else {
        return createProfileAnonymous({ name });
      }
    },
    [
      anonymousLearnerProfileId,
      createProfileAnonymous,
      profileId,
      setAnonymousLearnerProfileId,
      updateAnonymousUser,
    ],
  );
  const userHandle = useMemo(
    () => ({
      ...user,
      id: user?.id.toString(),
    }),
    [user],
  );

  const { t, lang } = useTranslate('common');

  const messages = useMemo(
    () => ({
      descAnnouncement: t('toast_error.desc_announcement'),
      titleAnnouncement: t('toast_error.title_announcement'),
      loginAgain: t('label.login_again'),
      login: t('label.login'),
      loginWarningTitle: t('modal:toast_important.login.title'),
      cancelSkip: t('label.cancel_skip'),
    }),
    [t],
  );

  useSubscription(AuthProviderViewerSessionEvent, {
    context: { v2: true },
    skip: !user?.id,
    onData({ data: { data: viewerSessionEventData } }) {
      if (viewerSessionEventData?.viewerSessionEvent) {
        // const { event } = viewerSessionEventData.viewerSessionEvent;
        // if (
        //   event === ViewerSessionEventType.NewSession ||
        //   event === ViewerSessionEventType.RestoredDiamondInAppPurchases
        // ) {
        //   return refetch();
        // }
        return refetch();
      }
    },
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  const onHandleSession = useCallback(
    (logoutFrom: string) => {
      toast.warning(messages.descAnnouncement, {
        title: `${messages.titleAnnouncement}: ${logoutFrom}`,
        important: true,
        id: 'alert_session',
        rightRender: ({ dismiss }) => {
          return (
            <div className="flex">
              <ModalButton
                colorScheme="gray"
                className="text-white uppercase text-[14px]"
                wrapClassName="mr-3"
                onClick={() => {
                  dismiss();
                  return logOut();
                }}
              >
                {messages.cancelSkip}
              </ModalButton>
              <ModalButton
                colorScheme="orange"
                className="text-white uppercase text-[14px]"
                onClick={() => {
                  dismiss();
                  const path = router.asPath === '/edit-profile' ? '/' : router.asPath;
                  return logOut(false).finally(() => router.push(ROUTES.SIGN_IN(path)));
                }}
              >
                {messages.loginAgain}
              </ModalButton>
            </div>
          );
        },
      });
    },
    [
      logOut,
      messages.cancelSkip,
      messages.descAnnouncement,
      messages.loginAgain,
      messages.titleAnnouncement,
      router,
    ],
  );
  const showLoginWarningRef = useRef(false);
  useEffect(() => {
    const isExcludedRoute = EXCLUDE_REQUIRED_SIGN_IN.some((item) =>
      router.pathname.startsWith(item),
    );
    if (
      !loading &&
      !data?.viewerSession.viewer &&
      !isExcludedRoute &&
      !showLoginWarningRef.current
    ) {
      showLoginWarningRef.current = true;
      toast.warning('', {
        title: messages.loginWarningTitle,
        important: true,
        id: 'alert_login_warning',
        rightRender: ({ dismiss }) => {
          return (
            <div className="flex">
              <ModalButton
                colorScheme="gray"
                className="text-white uppercase text-[14px]"
                wrapClassName="mr-3"
                onClick={dismiss}
              >
                {messages.cancelSkip}
              </ModalButton>
              <ModalButton
                colorScheme="orange"
                className="text-white uppercase text-[14px]"
                onClick={() => {
                  dismiss();
                  const path = router.asPath === '/edit-profile/info-user' ? '/' : router.asPath;
                  return logOut(false).finally(() => router.push(ROUTES.SIGN_IN(path)));
                }}
              >
                {messages.login}
              </ModalButton>
            </div>
          );
        },
      });
    }

    if (!isExcludedRoute) {
      const deactivatedBySession = data?.viewerSession.loginSession?.deactivatedBySession;
      if (!deactivatedBySession) {
        return;
      }
      time.locale(lang);
      onHandleSession(
        `${deactivatedBySession.userAgent.summary} - ${time(
          deactivatedBySession.createdAt,
        ).fromNow()}`,
      );
    }
  }, [
    data?.viewerSession.loginSession?.deactivatedBySession,
    data?.viewerSession.viewer,
    lang,
    loading,
    logOut,
    messages.cancelSkip,
    messages.login,
    messages.loginAgain,
    messages.loginWarningTitle,
    onHandleSession,
    router,
  ]);

  // handler utm
  useEffect(() => {
    const domainName = window.location.hostname.replace(/^(.*\.)?([^.]+\.[^.]+)$/, '$2');
    const timeExpires = 60 * 60 * 24;
    if (typeof router.query.utm_source === 'string') {
      setUtmSource(router.query.utm_source, {
        sameSite: 'none',
        secure: true,
        expires: timeExpires,
        domain: domainName,
      });
    }
    if (typeof router.query.utm_medium === 'string') {
      setUtmMedium(router.query.utm_medium, {
        sameSite: 'none',
        secure: true,
        expires: timeExpires,
        domain: domainName,
      });
    }
    if (typeof router.query.utm_campaign === 'string') {
      setUtmCampaign(router.query.utm_campaign, {
        sameSite: 'none',
        secure: true,
        expires: timeExpires,
        domain: domainName,
      });
    }
    if (typeof router.query.utm_content === 'string') {
      setUtmContentId(router.query.utm_content, {
        sameSite: 'none',
        secure: true,
        expires: timeExpires,
        domain: domainName,
      });
    }
  }, [
    router.query.utm_campaign,
    router.query.utm_content,
    router.query.utm_medium,
    router.query.utm_source,
    setUtmCampaign,
    setUtmContentId,
    setUtmMedium,
    setUtmSource,
  ]);
  // handler cookie mgm
  useEffect(() => {
    if (typeof router.query.referral_code === 'string') {
      // expires 1 day
      setMgmReferralCode(router.query.referral_code, {
        sameSite: 'none',
        secure: true,
        expires: 60 * 60 * 24,
        domain: window.location.hostname.replace(/^(.*\.)?([^.]+\.[^.]+)$/, '$2'),
      });
    }
  }, [router.query.referral_code, setMgmReferralCode]);

  const isFlyerUs = useMemo(() => {
    if (user?.countryCode) {
      return user?.countryCode !== 'VN';
    }
    return false;
  }, [user?.countryCode]);

  const value = useMemo(() => {
    return {
      loading,
      user: user ? userHandle : undefined,
      currentLearnerProfile,
      currentLearnerProfileId: profileId || anonymousLearnerProfileId,
      learnerProfiles,
      logOut,
      writeQueryViewer,
      switchLearnerProfile,
      getCurrentLearnerProfileId,
      reFetchViewer: refetch,
      isLogin,
      isFlyerUs,
    };
  }, [
    anonymousLearnerProfileId,
    currentLearnerProfile,
    getCurrentLearnerProfileId,
    learnerProfiles,
    loading,
    logOut,
    profileId,
    refetch,
    switchLearnerProfile,
    user,
    userHandle,
    writeQueryViewer,
    isLogin,
    isFlyerUs,
  ]);

  return <context.Provider value={value}>{children}</context.Provider>;
}
